.object-data-container {
  background-color: #191a1d;
  color: #ccc;
  max-width: 0%;
  padding: 10px;
  width: 10vw;
  min-width: 250px;
  z-index: 10;
  border-radius: 0.5em;
  margin: 1em;
  right: 0;
  pointer-events: none;
}

.field-container {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.field {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.field label {
  margin-right: 10px;
}

.rounded-input {
  width: 40%;
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 8px;
  border-radius: 8px;
  border: 1px solid #3a3e47;
  margin: 5px;
  text-align: center;
  color: #ccc;
  background-color: #3a3e47;
}

.rounded-input-trigger {
  width: 85%;
  height: 25px;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #3a3e47;
  margin: 3px;
  text-align: center;
  color: #ccc;
  background-color: #3a3e47;
}

.rounded-inspector {
  width: 25%;
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 8px;
  border-radius: 8px;
  border: 1px solid #3a3e47;
  margin: 5px;
  text-align: center;
  color: #ccc;
  background-color: #3a3e47;
  pointer-events: all;
}

.rounded-inspector-name {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  margin: 5px 0 15px 0;
  border-radius: 8px;
  border: 1px solid #3a3e47;
  text-align: center;
  color: #ccc;
  background-color: #3a3e47;
  pointer-events: all;
}

.select-trigger {
  margin-top: 10px;
  border-radius: 5px;
  background-color: #3a3e47;
  color: #ccc;
  padding: 5px;
  margin: 5px;
  margin-left: 10px;
  cursor: pointer;
  pointer-events: all;
}

.select-trigger-button-up{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
  background-color: #191a1d; 
  color: #ccc;
  border-radius: 20%;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-left: 10px;
  pointer-events: all;
}

.select-trigger-button-up:hover{
  background-color: #3a3e47;
}

.select-trigger-button-down{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
  background-color: #191a1d; 
  color: #ccc;
  border-radius: 20%;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-left: 10px;
  pointer-events: all;
}

.select-trigger-button-down:hover{
  background-color: #3a3e47;
}

h3 {
  margin-left: 5px;
  color: grey;
  font-size: 0.95rem;
}

span {
  margin-left: 5px;
  color: grey;
}

.button-model {
  margin-left: 0.5rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #191a1d;
  color: #ccc;
  border-radius: 2rem;
  border: 1px solid #ccc;
  cursor: pointer;
  outline: none;
  margin: 0.5rem;
  width: 75%;
  }

  .labelApp {
    color: #ccc;
  }

  .triggers-container{
    border-radius: 5px;
    border: 1px solid #3a3e47;
    padding: 5px;
    padding-left: 20px;
    margin-bottom: 7px;
  }

  .triggers-field{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .triggers-div{
    margin-right: 10px;
    width: 120px;
  }

  .triggers-label{
    font-size: 12px;
  }

  .iconVideo {
    color: #ccc;
    cursor: pointer;
    pointer-events: all;
  }

  .iconTrash {
    color: #ccc;
    cursor: pointer;
    pointer-events: all;
  }

  .delete-button {
    margin: 20px auto;
    width: 150px;
    height: 30px;
    cursor: pointer;
    background-color: #191a1d; 
    color: #ccc;
    border-radius: 20px;
    border: 1px solid darkred;
    cursor: pointer;
    display: block;
    pointer-events: all;
  }
  
  .delete-button:hover {
    background-color: darkred;
  }
  