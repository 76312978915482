.object-wks-container {
  background-color: #191a1d;
  color: #ccc;
  padding: 1rem; 
  position: fixed; 
  top: 12%;
  right: 5.3%;
  border-radius: 0.5em;
  z-index: 10;
  width: 25vw; 
  max-width: 250px; 
  min-width: 200px; 
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
}

.field-wks-container {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.apply-button {
  margin: 20px auto;
  width: 150px;
  height: 30px;
  cursor: pointer;
  background-color: #191a1d; 
  color: #ccc;
  border-radius: 20px;
  border: 1px solid #646464;
  cursor: pointer;
  display: block;
  pointer-events: all;
}

.apply-button:hover {
  background-color: #3a3e47;
}

.rounded-input {
  width: 40%;
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 8px;
  border-radius: 8px;
  border: 1px solid #3a3e47;
  margin: 5px;
  text-align: center;
  color: #ccc;
  background-color: #3a3e47;
  pointer-events: all;
}

.close-button {
  margin: 20px auto;
  width: 150px;
  height: 30px;
  cursor: pointer;
  background-color: #191a1d; 
  color: #ccc;
  border-radius: 20px;
  border: 1px solid #646464;
  cursor: pointer;
  display: block;
  pointer-events: all;
}

.close-button:hover {
  background-color: #3a3e47;
}
