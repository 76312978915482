.App-project {
  margin: 0;
  overflow: hidden;
}

.gradient-backgroundP {
  width: 100%;
  height: 100vh;
  background: hsl(225 7% 17% / 1);
}

.modal {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 900px;
  max-height: 750px;
  overflow-y: auto;
  background: #191a1d;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
}

  .modal-main h2 {
    text-align: center;
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
  
  .formPopUpP {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 50px;
    margin-right: 50px;
  }
  
  .labelPopUpP {
    color: grey;
    font-size: 0.85rem;
    margin-top: 1rem;
}
  
.inputPopUpP,
.selectPopUpP {
    width: 100%;
    padding: 0.5rem;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.875rem;
    background-color: #191a1d;
    color: white;
    pointer-events: all;
}

.popup-button-inP {
  background-color: #5832ff;
  color: #ccc;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  margin-top: 5rem;
  border-radius: 20px;
  width: 95%;
  margin-bottom: 25px;
  pointer-events: all;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

  
  .popup-button-inP:hover {
    background-color: #2c1885;
    font-weight: bold;
  }

  .input-group {
    display: flex;
    gap: 5px; 
  }
  
  #dimensionX,
  #dimensionY {
    width: 50%;
    box-sizing: border-box;
  }

  .tabs {
    display: flex;
    margin-bottom: 20px;
    pointer-events: all;
  }
  
  .tab {
    flex: 1;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    color: #ccc;
    border: 1px solid black;
    background-color: #393b42;
  }
  
  .tab.active {
    color: #ccc;
    background-color: #5832ff;
    font-weight: bold;
  }

  .table-container {
    display: flex;
    flex-direction: column;
    max-height: 352px;
    overflow-y: auto;
    display: block;
    pointer-events: all;
  }

  .table-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .table-container::-webkit-scrollbar-thumb {
    background-color: #888;
    border: 2px solid #ccc;
  }
  
  .table-container::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  
  .table-container::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  .table {
    font-size: 0.85rem;
    color: white;
    pointer-events: all;
    border: 1px solid #ccc;
    border-collapse: separate;
    border-spacing: 0;
  }

  .table thead, .table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .table th:first-child, .table td:first-child {
    width: 30%;
  }
  
  .table th:nth-child(2), .table td:nth-child(2) {
    width: 65%;
  }

  .table th:nth-child(3), .table td:nth-child(3) {
    width: 5%;
    text-align: center;
  }
  
  .table th, .table td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ccc;
  }
  
  .table tr {
    border-bottom: 1px solid #ccc;
    user-select: none;
  }
  
  .table-active {
    background-color: #3a3e47;
    color: white;
  }

  .dropTableIcon {
    margin-left: 5px;
    cursor: pointer;
    pointer-events: all;
  }

  .templateRectangles-p1{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: flex-start;
    width: 100%;
  }

  .templateRectangles-p2{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid white;
    border-radius: 0.3rem;
    padding: 1rem;
    width: 16rem;
    max-width: 100%;
    pointer-events: all;
    user-select: none;
    box-sizing: border-box;
    height: 8rem;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    transition: filter 0.3s ease;
  }

  .templateRectangles-p2.selected {
    border-color: #5832ff;
    border-width: 5px;
  }

  .templateRectangles-p2:hover::after {
    background: rgba(0, 0, 0, 0);
  }
  
  .templateRectangles-p2.selected::after {
    background: rgba(0, 0, 0, 0);
  }

  .templateRectangles-p2 {
    position: relative;
    overflow: hidden;
  }
  
  .templateRectangles-p2::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.65); 
    transition: background 0.3s ease;
    z-index: 1; 
  }

  .templateRectangle-form{
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    text-align: center;
    margin-bottom: 1rem;
    position: relative;
    z-index: 2;
  }

  .deep-link-btn {
    display: inline-block;
    background-color: #5832ff;
    color: #fff;
    padding: 6px 12px;
    font-size: 0.85rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .deep-link-btn:hover {
    background-color: #2c1885;
    transform: scale(1.05);
  }
  
  .deep-link-btn:active {
    background-color: #1a0e5c;
    transform: scale(0.95);
  }
  