.container-TS {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
  
  .canvas-TS {
    width: 50%;
    height: 100%;
    display: block;
  }
  
  .button-TS {
    position: absolute;
    bottom: 4vh;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    background: rgba(169, 169, 169, 0.5);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
  }
  
  .icon-TS {
    color: #ccc;
    font-size: 18px;
  }
  