.asset-data {
    width: 25%;
    background-color: #191a1d;
    color: #ccc;
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 10vw;
    min-width: 250px;
    z-index: 10;
    border-radius: 0.5em;
    margin: 1em;
    pointer-events: none;
  }
  
  .asset-data h3 {
    margin-left: 5px;
    color: grey;
    font-size: 0.95rem;
  }
  
  .asset-list {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .asset-item {
    padding: 0.5rem;
    border-radius: 4px;
    margin-bottom: 25px;
    color: #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    pointer-events: all;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%; 
  }

  .iconStyleAsset {
    color: #ccc;
    cursor: pointer;
    pointer-events: all;
  }

  .camera-list {
    display: flex;
    flex-direction: column;
  }
  
  .camera-item {
    padding: 0.5rem;
    border-radius: 5px;
  }
  
  .camera-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.1rem;
  }
  
  .fieldCameras {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .rounded-inspector-camera {
    width: 25%;
    padding: 8px;
    box-sizing: border-box;
    margin-bottom: 8px;
    border-radius: 8px;
    border: 1px solid #3a3e47;
    margin: 5px;
    text-align: center;
    color: #ccc;
    background-color: #3a3e47;
    pointer-events: all;
  }

  .dropViwInfoCamera {
    margin-left: 5px;
    cursor: pointer;
    pointer-events: all;
  }

  .asset-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 80%;
    display: inline-block;
  }