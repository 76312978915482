.contenedor-canvas {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  pointer-events: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}
input[type=number] {
-moz-appearance: textfield;
}

html {
  overflow: hidden;
}

body {
  overflow: hidden;
  pointer-events: none;
}

::file-selector-button {
  display: none;
}

.notification-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #191a1d;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  z-index: 10000;
  width: 300px;
  max-width: 90%;
  text-align: center;
  pointer-events: all;
}

.notification-main {
  display: flex;
  flex-direction: column;
}

.notification-message{
  color: grey;
}

.close-notification {
  margin-top: 15px;
  cursor: pointer;
  background-color: #191a1d;
  color: #ccc;
  border-radius: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
  pointer-events: all;
}

.close-notification:hover {
  background-color: #3a3e47;
}

