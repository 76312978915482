.object-Audioloader-container {
  background-color: #191a1d;
  color: #ccc;
  padding: 1rem; 
  position: fixed; 
  top: 12%;
  left: 35%;
  border-radius: 0.5em;
  z-index: 10;
  width: 25vw; 
  max-width: 250px; 
  min-width: 200px; 
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
}

.field-Audioloader-container {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.Audioloader-button {
  margin-bottom: 20px;
  width: 100%;
  height: 30px;
  cursor: pointer;
  background-color: #191a1d; 
  color: #ccc;
  border-radius: 20px;
  border: 1px solid #646464;
  cursor: pointer;
  display: block;
  pointer-events: all;
}

.Audioloader-button.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.Audioloader-button:hover {
  background-color: #3a3e47;
}

.urlLoader-input {
  margin-bottom: 10px;
  width: 100%;
  padding: 6px;
  box-sizing: border-box;
  border-radius: 20px;
  border: 1px solid #3a3e47;
  text-align: center;
  color: #ccc;
  background-color: #3a3e47;
  pointer-events: all;
}

.close-button {
  margin: 20px auto;
  width: 150px;
  height: 30px;
  cursor: pointer;
  background-color: #191a1d; 
  color: #ccc;
  border-radius: 20px;
  border: 1px solid #646464;
  cursor: pointer;
  display: block;
  pointer-events: all;
}

.close-button:hover {
  background-color: #3a3e47;
}
