.App-header {
    width: 100%;
    padding: 1rem 0;
    background: linear-gradient(to right, #5832ff, #F5F5DC);
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    color: #6f50f6;
    height: 0.25rem;
}

.navbar-label {
    font-size: 24px;
}

.buttons-container {
    display: flex;
}

.nav-button {
    margin-left: 10px;
    padding: 12px 24px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    background-color: #ec655f;
    color: #fff;
    cursor: pointer;
    outline: none;
  }
  
.nav-button:hover {
    background-color: #6f50f6;
  }

.xreco-logo{
    width: 100px;
    margin-left: 25px;
}

.left-text{
    color: #ccc;
    font-weight: bold;
}

.right-text{
    color: black;
    cursor: pointer;
    pointer-events: all;
    font-weight: bold;
}