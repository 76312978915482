.App-project {
  margin: 0;
  overflow: hidden;
}

.gradient-backgroundL {
  width: 100%;
  height: 100vh;
  background: hsl(225 7% 17% / 1);
}

.modalLogin {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 600px;
  max-height: 500px;
  overflow-y: auto;
  background: #191a1d;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
}

.h2L {
  color: grey;
}

  .modal-json {
    position: fixed;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1010px;
    height: 710px;
    background: #191a1d;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9999;
    pointer-events: all;
    background-color: #191a1d;
  }

  .modal-main h2 {
    text-align: center;
  }

  .modal-json textarea{
    background-color: #3a3e47;
    width: 1000px;
    height: 600px;
    border-radius: 8px;
    color: #ccc
  }
  
  .modal-json h3{
    color: grey;
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
  
  .formPopUpL {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 50px;
    margin-right: 50px;
  }
  
  .labelPopUpL {
    color: grey;
    font-size: 0.85rem;
    margin-top: 1rem;
}
  
.inputPopUpL,
.selectPopUpL {
    width: 100%;
    padding: 0.5rem;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.875rem;
    background-color: #191a1d;
    color: white;
    pointer-events: all;
}


  .popup-button-inL {
    background-color: #ec655f;
    color: #191a1d;
    padding: 0.5rem;
    border: none;
    cursor: pointer;
    margin-top: 5rem;
    border-radius: 20px;
    width: 100%;
    margin-bottom: 25px;
    pointer-events: all;
}
  
  .popup-button-inL:hover {
    background-color: #a1443f;
  }

  .input-group {
    display: flex;
    gap: 5px; 
  }
  
  #dimensionX,
  #dimensionY {
    width: 50%;
    box-sizing: border-box;
  }

  .error-message{
    color:#ec655f;
    font-size: 0.875rem;
    align-items: center;
  }